<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="notification-item">
    <BufferImage
      v-if="loading"
      color="black"
      float="center"
    />
    <CRMCard
      v-for="notification in notifications"
      :key="notification.id"
      :title="notification.title || categories[notification.category]"
      class="notification-card"
      :links="getLinks(notification)"
      @add-to-dashboard="addToDashboard(notification)"
      @dismiss="dismiss(notification)"
      @view-item="hideNotification(notification)"
    >
      <template #info>
        <h2 class="text-4">
          {{ notification.description || capitalize(notification.category) }}
        </h2>
        <p>
          {{ formatDate(notification.created_date) }} - {{ notification.created_by }} {{ notification.type === 'SharedEnquiry' ? t('shared an enquiry with you') : t('mentioned you in a') }} {{ notification.entity_type }}
        </p>
      </template>
    </CRMCard>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { useStore } from 'vuex';
import { t, updateWidgetToAdd } from '@sales-i/utils';
import { BufferImage, CRMCard } from '@sales-i/dsv3';
import { DateTime } from 'luxon';
import { DISMISS_MENTION, CLEAR_VISIBLE, DELETE_BY_ID } from '@/shared/store/actionType';
import { capitalize } from '@/shared/utils/strings';
import { useRoute } from 'vue-router';

const store = useStore();
const categories = {
  mention: 'Mention',
  missed_opportunity: 'Missed opportunities',
  potential_opportunity: 'Potential opportunities',
  update: 'Update',
  today: 'Today',
};

const hideNotification = () => store.dispatch(`notifications/${CLEAR_VISIBLE}`);
// When the route changes, call hideNotification to close the notifications
const route = useRoute();
watch(route, hideNotification);

// Make a call to the dismiss endpoint with an id in the response body
const dismissMentions = notification => store.dispatch(`mentions/${DISMISS_MENTION}`, [notification.id]);
const dismissNotifiations = notification => store.dispatch(`notifications/${DELETE_BY_ID}`, { id: notification.id });

const dismiss = notification => {
  if (notification.type === 'SharedEnquiry') {
    dismissNotifiations(notification);
  } else {
    dismissMentions(notification);
  }
};

const getTypeAndParams = path => {
  let reportType = '';
  // finds segment after "enquiry"
  const match = path.match(/\/enquiry\/([^/]+)/);
  if (match && match[1]) {
    reportType = match[1];
  }
  const search = new URL(`${location.origin}${path}`);
  const searchParams = new URLSearchParams(search.search);
  const requestParams = {};
  searchParams.forEach((value, key) => {
    requestParams[key] = value;
  });
  return {
    reportType,
    requestParams
  };
};

const addToDashboard = notification => {
  const typeAndParams = getTypeAndParams(notification.path);
  updateWidgetToAdd({
    fixed: false,
    order: 10000,
    width: 1,
    height: 1,
    reportType: typeAndParams.reportType,
    name: notification.title,
    description: notification.description,
    requestParams: typeAndParams.requestParams,
    settings: { url: notification.path },
  }, true);
};

defineProps({
  notifications: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
  },
  loaded: {
    type: Boolean,
  },
  type: {
    type: String,
    default: '',
  },
});

const formatDate = date => {
  return DateTime.fromISO(date).toFormat('dd LLL yyyy : HH:mm');
};

function getLinks(notification) {
  let links = [];

  if (notification.type === 'SharedEnquiry') {
    links.push({
      text: 'View',
      type: 'a',
      href: `${notification.path}`,
    }, {
      text: 'Add to Dashboard',
      id: notification.id,
    });
  }

  links.push({
    text: 'Dismiss',
    id: notification.id,
  });

  if (notification.category === 'MENTION') {
    links.push({
      text: 'View mentions',
      type: 'a',
      href: `${notification.path}`,
    });
  }
  if (notification.category === 'Missed Opportunities') {
    links.push({
      text: 'View missed opportunities',
    });
  }

  return links;
}
</script>

<style lang="scss" scoped>
@import '@/shared/assets/scss/_variables';
.notification-item {
  overflow: auto;
}
.notification-card {
  position: relative;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 2px solid var(--colour-panel-g-16);
}
.more-btn {
  display: block;
  margin: var(--spacing-1) auto;
}
.menu-button {
  position: absolute;
  top: var(--spacing-2);
  right: var(--spacing-1);
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    top: var(--spacing-3);
    right: var(--spacing-2);
  }
}
</style>
